import { Image } from 'expo-image';
import { router } from 'expo-router';
import { useLocalSearchParams } from 'expo-router';
import { useCallback, useMemo } from 'react';
import { ImageStyle, StyleProp, TouchableOpacity, View } from 'react-native';

import {
  ActionSheet,
  Button,
  IconClose,
  IconDelete,
  ScrollView,
  Text,
  createMqStyles,
  tokens,
} from '@fhs/ui';
import { IncentiveEvaluationErrorCodes } from '@fhs-legacy/frontend/src/state/loyalty/hooks/types';
import { routes } from '@fhs-legacy/frontend/src/utils/routing';

import { OfferDetailsSkeleton } from '../../components';
import { useApplyOffer } from '../../hooks/use-apply-offer';
import { useLoyaltyLegacyStates } from '../../hooks/use-loyalty-legacy-states';
import { useRemoveOffer } from '../../hooks/use-remove-offer';
import { useOfferIncentive } from '../../queries/loyalty.queries';

import { ErrorRenderer } from './error-renderer';

export function OfferDetailScreen() {
  const styles = useMqStyles();
  const { offerId } = useLocalSearchParams<{
    offerId: string;
  }>();
  const { loyaltyUserReady, selectedOffer } = useLoyaltyLegacyStates();
  const { data: currentOffer, isLoading: isLoyaltyIncentivesLoading } = useOfferIncentive(offerId);
  const isLoading = isLoyaltyIncentivesLoading || !loyaltyUserReady;
  const isOfferSelected = useMemo(
    () => selectedOffer?._id === offerId,
    [offerId, selectedOffer?._id]
  );
  const { applyOffer } = useApplyOffer();
  const { removeOffer } = useRemoveOffer();

  const error = currentOffer?.errors?.[0] as IncentiveEvaluationErrorCodes | undefined;
  const onClose = useCallback(() => {
    router.dismiss();
  }, []);
  const onPressRemoveOffer = useCallback(() => {
    router.dismiss();
    removeOffer();
  }, [removeOffer]);

  const onPressApplyOffer = useCallback(() => {
    if (currentOffer) {
      router.dismiss();
      applyOffer(currentOffer);
      if (!currentOffer.requireGuideFlow) {
        router.navigate(routes.menu);
      }
    }
  }, [currentOffer, applyOffer]);

  return (
    <ActionSheet isVisible onClose={onClose}>
      <View style={styles.headerContainer}>
        <Text.Ui size="md" weight="semibold" style={styles.headerTitle}>
          Offer Details
        </Text.Ui>
        <TouchableOpacity style={styles.closeIcon} onPress={onClose}>
          <IconClose size={24} />
        </TouchableOpacity>
      </View>
      {isLoading ? (
        <OfferDetailsSkeleton textBlockLines={15} />
      ) : (
        <View style={styles.content}>
          <View style={styles.imageContainer}>
            <Image
              style={styles.image as StyleProp<ImageStyle>}
              source={{ uri: currentOffer?.image }}
              contentFit="contain"
            />
          </View>

          <View style={styles.title}>
            <Text.Heading type="three" style={styles.name}>
              {currentOffer?.name}
            </Text.Heading>
            <Text.Ui size="md" style={styles.description}>
              {currentOffer?.description}
            </Text.Ui>
          </View>
          {error && <ErrorRenderer error={error} />}

          <ScrollView style={styles.scrollViewContainer}>
            <Text.Ui style={styles.termsAndConditions} size="md">
              {currentOffer?.termsAndConditions}
            </Text.Ui>
          </ScrollView>
          <View style={styles.footer}>
            {isOfferSelected ? (
              <Button style={styles.buttonContainer} size="md" onPress={onPressRemoveOffer}>
                <Button.Icon color={tokens.colors.$white}>
                  <IconDelete />
                </Button.Icon>
                <Button.Text style={styles.buttonLabel}>Remove Offer</Button.Text>
              </Button>
            ) : (
              <Button
                disabled={!currentOffer?.isAvailable}
                style={styles.buttonContainer}
                size="md"
                onPress={onPressApplyOffer}
              >
                <Button.Text style={styles.buttonLabel}>Apply Offer</Button.Text>
              </Button>
            )}
          </View>
        </View>
      )}
    </ActionSheet>
  );
}

const useMqStyles = createMqStyles({
  headerContainer: {
    $base: {
      paddingVertical: 12,
      paddingHorizontal: 16,
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      borderBottomWidth: 1,
      borderColor: tokens.colors.$black10,
    },
    $gteDesktop: {
      paddingVertical: 28,
      paddingHorizontal: 28,
      justifyContent: 'flex-start',
    },
  },
  closeIcon: {
    $base: {
      position: 'absolute',
      right: 16,
    },
  },
  header: {
    $gteDesktop: {
      alignItems: 'center',
      justifyContent: 'space-between',
      flexDirection: 'row',
      padding: 28,
      height: 90,
      width: 520,
      backgroundColor: tokens.colors.$white,
    },
  },
  headerTitle: {
    $base: {
      fontSize: 16,
    },
    $gteDesktop: {
      fontSize: 24,
    },
  },
  content: {
    $base: {
      flex: 1,
      justifyContent: 'space-between',
      backgroundColor: tokens.colors.$white,
    },
    $gteDesktop: {
      width: 520,
    },
  },

  imageContainer: {
    $base: {
      borderBottomWidth: 1,
      borderBottomColor: tokens.colors.$blackOpacity04,
    },
  },
  image: {
    $base: {
      height: 216,
    },
  },
  title: {
    $base: {
      borderBottomWidth: 1,
      borderBottomColor: tokens.colors.$blackOpacity04,
      marginTop: 24,
      marginLeft: 16,
      marginRight: 16,
    },
    $gteDesktop: {
      marginLeft: 24,
      marginRight: 24,
    },
  },
  name: {
    $base: {
      textAlign: 'center',
      fontSize: 24,
      lineHeight: 34,
      paddingTop: 4,
      color: tokens.colors.$black,
      fontWeight: 600,
      marginBottom: 4,
    },
    $gteDesktop: {
      fontSize: 30,
      paddingBottom: 8,
    },
  },
  description: {
    $base: {
      textAlign: 'center',
      fontSize: 14,
      color: tokens.colors.$black,
      marginBottom: 20,
    },
    $gteDesktop: {
      fontSize: 16,
    },
  },
  scrollViewContainer: {
    $base: {
      flex: 1,
      paddingLeft: 16,
      paddingRight: 16,
      maxHeight: 250,
    },
    $gteDesktop: {
      paddingLeft: 28,
      paddingRight: 28,
    },
  },
  termsAndConditions: {
    $base: {
      color: tokens.colors.$blackOpacity55,
      fontSize: 12,
      lineHeight: 16.8,
      paddingVertical: 20,
    },
    $gteDesktop: {
      fontSize: 14,
      lineHeight: 19.6,
      paddingVertical: 24,
    },
  },
  footer: {
    $base: {
      borderTopWidth: 1,
      borderTopColor: tokens.colors.$blackOpacity10,
      alignItems: 'center',
      padding: 16,
    },
    $gteDesktop: {
      padding: 28,
    },
  },
  buttonContainer: {
    $base: {
      width: '100%',
      height: 48,
    },
  },
  buttonLabel: {
    $base: {
      fontSize: 18,
    },
  },
});
