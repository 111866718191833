import { router } from 'expo-router';
import { useEffect } from 'react';

import { Header } from '@fhs/backend/headers';
import { client, queryClient, setHeaders, useQuery } from '@fhs/client';
import { getCurrentSession } from '@fhs-legacy/frontend/src/remote/auth';
import { LaunchDarklyFlag, useFlag } from '@fhs-legacy/frontend/src/state/launchdarkly';
import { ServiceMode } from '@fhs-legacy/frontend/src/state/service-mode';
import { Toast } from '@fhs-legacy/native-base';

import { IDeliveryInputAddress } from './types';

export const getOrderTimeslots = {
  queryKey: ['order-timeslots'],
  queryFn: async () => client.queries.orderTimeslots(await _getAuthMode()).then(res => res.data),
};

export const getLegacyUserPaymentInformationQuery = {
  queryKey: ['legacy-user-payment-information'],
  queryFn: async () =>
    client.queries.legacyUserPaymentInformation(await _getAuthMode()).then(res => res.data),
};

type CartOptions = {
  throwOnError?: boolean;
};

export function useCartSubscription(options?: CartOptions) {
  const queryData = useCart(options);

  useEffect(() => {
    let unsubscribe = () => {};
    _getAuthMode().then(auth => {
      const sub = client.subscriptions.cartUpdates(auth).subscribe(cart => {
        queryClient.setQueryData(['cart'], cart, { updatedAt: Date.now() });
      });
      unsubscribe = sub.unsubscribe;
    });
    return unsubscribe;
  }, []);

  return queryData;
}

export function useCart(options?: CartOptions) {
  const cartServiceIsEnabled = useFlag(LaunchDarklyFlag.ENABLE_SIMPLY_BETTER_CART_SERVICE);

  const response = useQuery({
    throwOnError: () => options?.throwOnError ?? false,
    queryKey: ['cart'],
    queryFn: async () => {
      return client.queries
        .cart(await _getAuthMode())
        .then(res => {
          if ('errors' in res) {
            throw res.errors;
          }

          if (!res?.data) {
            throw new Error('UndetectableCartError');
          }
          if (res.data?.id) {
            setHeaders(Header.CART_ID, res.data.id);
          }
          return res.data;
        })
        .catch(_commonErrorHandler);
    },
    enabled: cartServiceIsEnabled,
  });

  // When the service is disabled, the isPending flag will still
  // be set to true, so we override it to force it false if we
  // disable the query.
  return {
    ...response,
    isPending: cartServiceIsEnabled && response.isPending,
  };
}

export const priceCart = {
  mutationFn: async () => {
    return client.mutations
      .priceCart(await _getAuthMode())
      .then(_cartUpdateHandler)
      .catch(_commonErrorHandler);
  },
};

export const commitOrder = {
  mutationFn: async ({ payment }: { payment: Record<string, any> }) => {
    return client.mutations
      .commitCart({ paymentJson: JSON.stringify(payment) }, await _getAuthMode())
      .then(_cartUpdateHandler)
      .catch(_commonErrorHandler);
  },
};

export const selectStore = {
  mutationFn: async ({
    storeNumber,
    serviceMode,
  }: {
    storeNumber: string;
    serviceMode: ServiceMode;
  }) => {
    return client.mutations
      .selectStore(
        {
          storeNumber,
          serviceMode,
        },
        await _getAuthMode()
      )
      .then(_cartUpdateHandler)
      .catch(_commonErrorHandler);
  },
};

export const setDeliveryRestaurant = {
  mutationFn: async ({ deliveryAddress }: { deliveryAddress: IDeliveryInputAddress | null }) => {
    return client.mutations
      .setDeliveryRestaurant(
        {
          dropoffAddressJson: JSON.stringify(deliveryAddress),
        },
        await _getAuthMode()
      )
      .then(_cartUpdateHandler)
      .catch(_commonErrorHandler);
  },
};

export const addOfferToCart = {
  mutationFn: async ({ offerId, entries: _entries }: { offerId: string; entries: any }) => {
    return client.mutations
      .addOfferToCart({ offerId }, await _getAuthMode())
      .then(_cartUpdateHandler)
      .catch(_commonErrorHandler);
  },
};

export const removeOfferfromCart = {
  mutationFn: async ({ offerId }: { offerId: string }) => {
    return client.mutations
      .removeOfferFromCart({ offerId }, await _getAuthMode())
      .then(_cartUpdateHandler)
      .catch(_commonErrorHandler);
  },
};

type Modifiers = Array<{ id: string; quantity: number }>;

export const addItemToCart = {
  mutationFn: async ({
    itemId,
    quantity,
    instructions,
    modifiers,
    isCombo,
  }: {
    itemId: string;
    quantity: number;
    instructions: string;
    modifiers: Modifiers;
    isCombo: boolean;
  }) => {
    return client.mutations
      .addItemToCart(
        {
          itemId,
          quantity,
          instructions,
          modifiersJson: JSON.stringify(modifiers),
          isCombo,
        },
        await _getAuthMode()
      )
      .then(_cartUpdateHandler)
      .catch(_commonErrorHandler);
  },
};

export const updateItemInCart = {
  mutationFn: async ({
    lineId,
    itemId,
    instructions,
    quantity,
    modifiers,
  }: {
    lineId: string;
    itemId?: string;
    instructions?: string;
    quantity?: number;
    modifiers?: Modifiers;
  }) => {
    return client.mutations
      .updateItemInCart(
        {
          lineId,
          instructions,
          itemId,
          quantity,
          modifiersJson: JSON.stringify(modifiers),
        },
        await _getAuthMode()
      )
      .then(_cartUpdateHandler)
      .catch(_commonErrorHandler);
  },
};

export const setDonationAmount = {
  mutationFn: async ({ amount }: { amount: number }) => {
    return client.mutations
      .setDonationAmount(
        {
          amount,
        },
        await _getAuthMode()
      )
      .then(_cartUpdateHandler)
      .catch(_commonErrorHandler);
  },
};

export const setOrderFulfillmentTime = {
  mutationFn: async ({ fulfillmentTime }: { fulfillmentTime: string }) => {
    return client.mutations
      .setOrderFulfillmentTime({ fulfillmentTime }, await _getAuthMode())
      .then(_cartUpdateHandler)
      .catch(_commonErrorHandler);
  },
};

export const usePointsOnItem = {
  mutationFn: async ({ lineId }: { lineId: string }) => {
    return client.mutations
      .usePointsOnItem({ lineId }, await _getAuthMode())
      .then(_cartUpdateHandler)
      .catch(_commonErrorHandler);
  },
};

export const removePointUsageFromItem = {
  mutationFn: async ({ lineId }: { lineId: string }) => {
    return client.mutations
      .removePointUsageFromItem({ lineId }, await _getAuthMode())
      .then(_cartUpdateHandler)
      .catch(_commonErrorHandler);
  },
};

// ----------------------------------------------------------------
// private
// ----------------------------------------------------------------

const _cartUpdateHandler = result => {
  if (result.errors) {
    throw result.errors;
  }
  if (result.data) {
    if (result.data.id) {
      setHeaders(Header.CART_ID, result.data.id);
    }
    queryClient.setQueryData(['cart'], result.data, { updatedAt: Date.now() });
    return result.data;
  }
  throw [new Error('UndetectableCartError')];
};

const _commonErrorHandler = async res => {
  let errors = Array.isArray(res) ? res : [res];

  for (let error of errors) {
    switch (error.message) {
      case 'MissingStoreError':
        router.push({
          pathname: '/store-locator/service-mode',
          params: {
            back: '../',
          },
        });
        // TODO: Blaine - get design advice here
        Toast.show({ title: 'You must select a store before continuing' });
    }
  }

  throw errors;
};

const _getAuthMode = async (): Promise<any> => {
  const session = await getCurrentSession();
  if (session) {
    return {
      authToken: 'Bearer ' + session.getIdToken().getJwtToken(),
      authMode: 'lambda',
    };
  }

  return { authMode: 'apiKey' };
};
