import { useCallback, useEffect, useRef, useState } from 'react';
import {
  Modal,
  Platform,
  Animated as RNAnimated,
  StyleSheet,
  View,
  useWindowDimensions,
} from 'react-native';
import { Gesture, GestureDetector, GestureHandlerRootView } from 'react-native-gesture-handler';
import Animated, {
  runOnJS,
  useAnimatedStyle,
  useSharedValue,
  withSpring,
  withTiming,
} from 'react-native-reanimated';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { IconClose, Pressable, Text, XStack, YStack } from '@fhs/ui';
import { useBoxRef } from '@fhs/utils';

import { tokens } from '../../tokens';
import { ActionSheetProps } from '../action-sheet/types';

import { useKeyboardState } from './use-keyboard-state';

export const BottomDrawer = ({ grabber, ...props }: ActionSheetProps) => {
  if (Platform.OS === 'web') {
    return <WebBottomDrawer {...props} />;
  }

  return <MobileBottomDrawer {...{ ...props, grabber }} />;
};

const MobileBottomDrawer = ({
  isVisible,
  onClose,
  headerTitle,
  children,
  onRequestClose,
  containerStyle,
  grabber = true,
}: ActionSheetProps) => {
  const { height } = useWindowDimensions();
  const offset = useSharedValue(height);
  const handleRequestCloseRef = useRef(onClose);
  const safeAreaInsets = useSafeAreaInsets();
  const { keyboardHeight } = useKeyboardState();

  const pan = Gesture.Pan()
    .onChange(event => {
      const offsetDelta = event.changeY + offset.value;
      offset.value = offsetDelta > 0 ? offsetDelta : withSpring(offsetDelta);
    })
    .onFinalize(() => {
      if (offset.value < height / 6) {
        offset.value = withSpring(0);
      } else {
        offset.value = withTiming(height, {}, () => {
          runOnJS(onClose)();
        });
      }
    });

  const animatedStyle = useAnimatedStyle(() => ({
    transform: [{ translateY: offset.value }],
  }));

  useEffect(() => {
    if (isVisible) {
      offset.value = withSpring(0, { damping: 30, stiffness: 100 });
    } else {
      offset.value = withTiming(height);
    }
  }, [isVisible, offset, height]);

  return (
    <Modal
      visible={isVisible}
      transparent
      onRequestClose={onRequestClose ?? handleRequestCloseRef.current}
    >
      <Pressable style={styles.overlay} onPress={onRequestClose ?? handleRequestCloseRef.current} />
      <GestureHandlerRootView style={{ flex: 1 }}>
        <GestureDetector gesture={pan}>
          <Animated.View
            style={[
              styles.drawerContainer,
              Platform.OS === 'ios' ? { bottom: keyboardHeight } : undefined,
              animatedStyle,
              { paddingBottom: Math.max(safeAreaInsets.bottom, 16) },
              containerStyle,
            ]}
          >
            {grabber ? <View style={styles.grabber} /> : null}
            {!!headerTitle && (
              <XStack style={styles.header}>
                <Text style={styles.headerTitle} weight="semibold">
                  {headerTitle}
                </Text>
                <Pressable style={styles.closeIcon} onPress={onClose}>
                  <IconClose size={24} />
                </Pressable>
              </XStack>
            )}
            <YStack style={[styles.drawer]}>{children}</YStack>
          </Animated.View>
        </GestureDetector>
      </GestureHandlerRootView>
    </Modal>
  );
};

const WebBottomDrawer = ({
  isVisible,
  onClose,
  headerTitle,
  children,
  onRequestClose,
  containerStyle,
}: ActionSheetProps) => {
  const [drawerHeight, setDrawerHeight] = useState(0);
  const handleRequestCloseRef = useBoxRef(() => {
    onClose();
  });
  const animation = useRef(new RNAnimated.Value(0));

  const openDrawer = useCallback(() => {
    RNAnimated.timing(animation.current, {
      toValue: 1,
      duration: 200,
      useNativeDriver: true,
    }).start();
  }, []);

  const closeDrawer = useCallback(() => {
    RNAnimated.timing(animation.current, {
      toValue: 0,
      duration: 300,
      useNativeDriver: true,
    }).start(() => onClose?.());
  }, [onClose]);

  const onLayout = useCallback(event => {
    const { height } = event.nativeEvent.layout;
    setDrawerHeight(height);
  }, []);

  useEffect(() => {
    if (isVisible) {
      openDrawer();
      return;
    }

    closeDrawer();
  }, [isVisible, openDrawer, closeDrawer]);

  const translateY = animation.current.interpolate({
    inputRange: [0, 1],
    outputRange: [drawerHeight, 0],
  });

  const opacity = animation.current.interpolate({
    inputRange: [0, 1],
    outputRange: [0, 0.75],
  });

  return (
    <Modal
      visible={isVisible}
      transparent
      style={styles.overlay}
      onRequestClose={onRequestClose ?? handleRequestCloseRef.current}
    >
      <RNAnimated.View style={[styles.fullScreen, { opacity }]}>
        <Pressable
          aria-label="Close dropdown"
          accessible={false}
          focusable={false}
          style={styles.fullScreen}
          onPress={handleRequestCloseRef.current}
        />
      </RNAnimated.View>
      <RNAnimated.View
        style={[styles.drawerContainer, { transform: [{ translateY }] }, containerStyle]}
      >
        {!!headerTitle && (
          <XStack style={styles.header}>
            <Text style={styles.headerTitle} weight="semibold">
              {headerTitle}
            </Text>
            <Pressable style={styles.closeIcon} onPress={onClose}>
              <IconClose size={24} />
            </Pressable>
          </XStack>
        )}
        <YStack style={styles.drawer} onLayout={onLayout}>
          {children}
        </YStack>
      </RNAnimated.View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  fullScreen: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: tokens.colors.$blackOpacity75,
  },
  overlay: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: tokens.colors.$blackOpacity75,
  },
  drawerContainer: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: tokens.colors.$white,
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
    paddingTop: 8,
    maxHeight: '90%',
  },
  header: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 16,
    minHeight: 56,
  },
  headerTitle: {
    fontSize: 16,
  },
  drawer: {
    flex: 1,
  },
  closeIcon: {
    position: 'absolute',
    right: 26,
  },
  grabber: {
    width: 40,
    height: 4,
    borderRadius: 100,
    backgroundColor: tokens.colors.$black10,
    alignSelf: 'center',
  },
});
