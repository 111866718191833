export enum ScrollRestorationKey {
  MenuList = 'menu-list',
}

const storageMap: Partial<Record<ScrollRestorationKey, number | undefined>> = {};

const resetOffset = (key: ScrollRestorationKey) => {
  storageMap[key] = undefined;
};

const storeOffset = (key: ScrollRestorationKey, offset: number) => {
  storageMap[key] = offset;
};

const getOffset = (key: ScrollRestorationKey) => {
  return storageMap[key];
};

export const scrollRestoration = {
  resetOffset,
  storeOffset,
  getOffset,
};
