import { useQueryClient } from '@tanstack/react-query';
import { Component, PropsWithChildren } from 'react';
import { StyleSheet, View } from 'react-native';

import { Button, IconErrorFill, tokens } from '@fhs/ui';
import { Text } from '@fhs/ui';
import logger from '@fhs-legacy/frontend/src/utils/logger';

export class GenericErrorScreen extends Component<PropsWithChildren> {
  state = {
    error: false,
  };

  componentDidCatch(error) {
    logger.error(error);
    this.setState({ error: true });
  }

  render() {
    if (this.state.error) {
      return <_GenericErrorScreen onRetry={() => this.setState({ error: false })} />;
    }

    return this.props.children;
  }
}

type Props = {
  onRetry(): void;
};
function _GenericErrorScreen(props: Props) {
  const queryClient = useQueryClient();
  return (
    <View style={styles.container}>
      <IconErrorFill size={80} color={tokens.colors.$blackOpacity10} />
      <View style={styles.textContainer}>
        <Text.Ui size="lg" weight="semibold" style={styles.text}>
          Oops, Something Went Wrong!
        </Text.Ui>
        <Text.Ui size="md" style={styles.text}>
          Please refresh the page or try again later.
        </Text.Ui>
      </View>
      <Button
        onPress={() => {
          queryClient.invalidateQueries({ queryKey: ['cart'] });
          props.onRetry();
        }}
      >
        <Button.Text>Try Again</Button.Text>
      </Button>
    </View>
  );
}

const styles = StyleSheet.create({
  text: {
    textAlign: 'center',
  },
  container: {
    gap: 16,
    flex: 1,
    backgroundColor: tokens.colors.$white,
    borderTopColor: tokens.colors.$black10,
    borderTopWidth: 1,
    paddingBottom: 200,
    alignItems: 'center',
    justifyContent: 'center',
  },
  textContainer: {
    gap: 8,
  },
});
