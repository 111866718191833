import { LinearGradient } from 'expo-linear-gradient';
import { View, useWindowDimensions } from 'react-native';

import {
  IconRewards,
  IconRewardsAndOffers,
  MqSwitch,
  Text,
  XStack,
  YStack,
  createMqStyles,
  tokens,
  useMqSelect,
} from '@fhs/ui';

const MobileTab = () => {
  const mqStyles = useMqStyles();
  const tabs = new Array(6).fill(null);
  return tabs.map((_, index) => (
    <View style={[mqStyles.tabBox, index === 0 && mqStyles.firstTabBox]} key={index}>
      <LinearGradient
        colors={[tokens.colors.$blackOpacity10, tokens.colors.$blackOpacity04]}
        style={mqStyles.tab}
        start={{ x: 0.0, y: 0 }}
        end={{ x: 0.2, y: 0 }}
        locations={[0.0, 1]}
      />
    </View>
  ));
};

const DesktopTab = () => {
  const mqStyles = useMqStyles();
  const tabs = new Array(10).fill(null);
  return tabs.map((_, index) => (
    <XStack style={mqStyles.tab} key={index}>
      <View style={mqStyles.webTabImage} />
      <View style={mqStyles.webTabDescription} />
    </XStack>
  ));
};

const ItemBox = () => {
  const mqStyles = useMqStyles();
  return (
    <View style={mqStyles.itemBox}>
      <View style={mqStyles.descriptionBox}>
        <View style={mqStyles.itemImage} />
        <View style={mqStyles.itemName} />
        <View style={mqStyles.itemDescriptionLine1} />
        <View style={mqStyles.itemDescriptionLine2} />
      </View>
    </View>
  );
};

const OffersAndRewardsTile = () => {
  const mqStyles = useMqStyles();

  return (
    <XStack style={mqStyles.offersAndRewardsTile}>
      <XStack style={mqStyles.offersAndRewards}>
        <IconRewardsAndOffers size={48} />
        <Text.Heading style={mqStyles.offersAndRewardsText} type="three">
          Offers
        </Text.Heading>
      </XStack>
      <XStack style={mqStyles.offersAndRewards}>
        <IconRewards size={48} />
        <Text.Heading style={mqStyles.offersAndRewardsText} type="three">
          Rewards
        </Text.Heading>
      </XStack>
    </XStack>
  );
};

function SkeletonItems() {
  const mqStyles = useMqStyles();
  const windowWidth = useWindowDimensions().width;
  const targetMinimumColumnWidth = useMqSelect({ $gteDesktop: 220 }, 200);
  const widthTakenUpBySidebar = useMqSelect({ $gteDesktop: 312 }, 0);
  const widthTakenUpByHorizontalPadding = useMqSelect({ $gteDesktop: 64 }, 32);
  const columnCount = Math.floor(
    Math.max(
      2,
      (windowWidth - widthTakenUpBySidebar - widthTakenUpByHorizontalPadding) /
        targetMinimumColumnWidth
    )
  );

  return (
    <XStack style={mqStyles.itemsRow}>
      {Array.from({ length: columnCount }, (_, i) => (
        <YStack style={mqStyles.itemColumn} key={i}>
          <ItemBox />
          <ItemBox />
          <ItemBox />
        </YStack>
      ))}
    </XStack>
  );
}

export const MenuListSkeletonLoading = () => {
  const mqStyles = useMqStyles();

  return (
    <View style={mqStyles.container}>
      <MqSwitch
        $ltDesktop={() => (
          <View style={mqStyles.flexDirectionRow}>
            <MobileTab />
          </View>
        )}
        $gteDesktop={() => (
          <View style={mqStyles.tabBox}>
            <DesktopTab />
          </View>
        )}
      />
      <YStack style={mqStyles.categoryBlock}>
        <XStack>
          <OffersAndRewardsTile />
        </XStack>

        <View style={mqStyles.category} />

        <SkeletonItems />
      </YStack>
    </View>
  );
};

const useMqStyles = createMqStyles({
  flexDirectionRow: {
    $base: {
      flexDirection: 'row',
    },
  },
  container: {
    $base: {
      overflow: 'hidden',
      flexShrink: 1,
    },
    $ltDesktop: {
      flex: 1,
    },
    $gteDesktop: {
      flexDirection: 'row',
    },
  },
  tab: {
    $ltDesktop: {
      width: 46,
      height: 20,
      borderRadius: 100,
      backgroundColor: tokens.colors.$blackOpacity04,
      top: 12,
    },
    $gteDesktop: {
      height: 52,
      width: 248,
      alignItems: 'center',
      paddingVertical: 6,
      paddingLeft: 16,
      marginTop: 12,
    },
  },
  webTabImage: {
    $base: {
      height: 40,
      width: 40,
      backgroundColor: tokens.colors.$houseLight,
      borderRadius: 12,
    },
  },
  webTabDescription: {
    $base: {
      height: 14,
      width: 78,
      backgroundColor: tokens.colors.$houseLight,
      borderRadius: 12,
      alignSelf: 'center',
      left: 13,
    },
  },
  tabBox: {
    $ltDesktop: {
      height: 44,
      width: 66,
      backgroundColor: tokens.colors.$white,
      flex: 1,
      alignContent: 'center',
      borderBottomWidth: 3,
      borderBottomColor: tokens.colors.$houseLight,
      alignItems: 'center',
    },
    $gteDesktop: {
      padding: 32,
      width: 310,
      borderRightWidth: 1,
      borderRightColor: tokens.colors.$houseLight,
    },
  },
  firstTabBox: {
    $base: {
      borderBottomColor: tokens.colors.$houseRedDarken,
      borderBottomWidth: 3,
    },
  },
  itemImage: {
    $base: {
      width: 110,
      height: 86,
      borderRadius: 12,
      backgroundColor: tokens.colors.$houseLight,
      position: 'absolute',
      top: -50,
    },
  },
  itemBox: {
    $base: {
      flexGrow: 1,
      flexShrink: 1,

      height: 140,
      maxHeight: 205,
      marginTop: 62,
    },
    $gteDesktop: {
      flex: 1,

      height: 140,
      marginTop: 80,
    },
  },
  descriptionBox: {
    $base: {
      borderRadius: 8,
      borderWidth: 1,
      borderColor: tokens.colors.$blackOpacity10,
      width: '100%',

      height: '100%',
      alignItems: 'center',
      paddingTop: 52,
    },
    $gteDesktop: {
      height: 148,
    },
  },
  itemName: {
    $base: {
      height: 20,
      width: 78,
      borderRadius: 12,
      backgroundColor: tokens.colors.$houseLight,
    },
  },
  itemDescriptionLine1: {
    $base: {
      height: 12,
      width: 124,
      borderRadius: 12,
      backgroundColor: tokens.colors.$houseLight,
      marginTop: 19,
    },
  },
  itemDescriptionLine2: {
    $base: {
      height: 12,
      width: 86,
      borderRadius: 12,
      backgroundColor: tokens.colors.$houseLight,
      marginTop: 5,
    },
  },
  category: {
    $base: {
      width: 228,
      height: 24,
      borderRadius: 100,
      backgroundColor: tokens.colors.$blackOpacity04,
      marginTop: 16,
    },
    $gteDesktop: {
      marginTop: 32,
      paddingTop: 3,
    },
  },
  categoryBlock: {
    $base: {
      padding: 16,
    },
    $gteDesktop: {
      padding: 32,
      paddingTop: 42,
      flexGrow: 1,
    },
  },
  itemsRow: {
    $base: { gap: 12 },
  },
  itemColumn: {
    $base: {
      flexShrink: 1,
      flexGrow: 1,
      gap: 20,
      justifyContent: 'center',
    },
  },
  offersAndRewardsTile: {
    $base: {
      flexDirection: 'row',
      flexGrow: 1,
      flexBasis: 0,
      gap: 12,
    },
  },
  offersAndRewards: {
    $base: {
      alignItems: 'center',
      height: 80,
      borderRadius: 8,
      borderColor: tokens.colors.$blackOpacity10,
      borderWidth: 1,
      padding: 16,
      overflow: 'hidden',
      flex: 1,
      paddingVertical: 12,
      paddingHorizontal: 16,
    },
  },
  offersAndRewardsText: {
    $base: {
      paddingHorizontal: 24,
    },
    $ltDesktop: {
      paddingHorizontal: 12,
    },
  },
});
