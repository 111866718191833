import { Stack, useLocalSearchParams, usePathname } from 'expo-router';
import { useEffect } from 'react';

import { useMqSelect } from '@fhs/ui';
import { ScrollRestorationKey, scrollRestoration } from '@fhs/utils';
import { LaunchDarklyFlag, useFlag } from '@fhs-legacy/frontend/src/state/launchdarkly';
import { isItemPickerOrComboRoute, isSectionRoute } from '@fhs-legacy/frontend/src/utils/routing';

import { getScreenTitleHeader } from '../../components/get-screen-title-header';

export default function MenuStackLayout() {
  const { id } = useLocalSearchParams<{ id: string }>();
  const pathname = usePathname();
  const sectionRoute = isSectionRoute(`/menu/${id}`);
  const isItemRoute = isItemPickerOrComboRoute(pathname);
  const isMobile = useMqSelect({ $ltDesktop: true }, false);

  // TODO(simplified-menu): Remove when simplified menu is out of beta
  const enableSimplifiedMenuBeta = useFlag<boolean>(LaunchDarklyFlag.ENABLE_SIMPLIFIED_MENU_BETA);
  const enableSimplifiedMenuBetaDetailView = useFlag<boolean>(
    LaunchDarklyFlag.ENABLE_SIMPLIFIED_MENU_BETA_DETAIL_VIEW
  );

  // TODO(simplified-menu): This is a hack to keep the back behavior correct in case we are rendering Menu V2 for legacy routes.
  // Without this, we would go back twice on the same page.
  const shouldBackToRoot = enableSimplifiedMenuBeta && (sectionRoute || pathname === '/menu');

  useEffect(() => {
    return () => {
      scrollRestoration.resetOffset(ScrollRestorationKey.MenuList);
    };
  }, []);

  return (
    <Stack
      screenOptions={{
        headerShown: isMobile || (isItemRoute && !enableSimplifiedMenuBetaDetailView),
        header: getScreenTitleHeader({ shouldBackToRoot }),
        title: 'Menu',
      }}
    />
  );
}

export const unstable_settings = {
  initialRouteName: '(menu)',
};
