import { Image } from 'expo-image';
import { StyleSheet, View } from 'react-native';

import { ActionSheet, Button, Text } from '@fhs/ui';

const logo = require('./foundation.svg');

type Props = {
  onClose(): void;
};
export function FoundationInformationModal(props: Props) {
  return (
    <ActionSheet isVisible onClose={props.onClose}>
      <View style={styles.container}>
        <View style={styles.imageContainer}>
          <Image source={logo} style={styles.image} />
        </View>
        <Text.Ui size="md" style={styles.text}>
          Your donations help provide lifesaving equipment and needed resources to first responders
          and public safety organizations, allowing them to be better prepared to save lives in the
          communities they serve.
        </Text.Ui>
        <Button type="outline" size="lg" onPress={props.onClose}>
          <Button.Text>Back To Cart</Button.Text>
        </Button>
      </View>
    </ActionSheet>
  );
}

const styles = StyleSheet.create({
  image: {
    height: 216,
    maxWidth: 342,
    aspectRatio: 342 / 216,
  },
  imageContainer: {
    alignItems: 'center',
  },
  text: {
    textAlign: 'center',
  },
  container: {
    gap: 24,
    paddingTop: 32,
    padding: 24,
  },
});
