import { type FlashList } from '@shopify/flash-list';
import { Image } from 'expo-image';
import { type ReactNode, useEffect, useMemo, useRef, useState } from 'react';

import { ScrollRestorationKey } from '@fhs/utils';

import { anchorSectionListContext } from './context';
import type { FlashListDatum, SectionType, TileSizeType } from './types';

type AnchorSectionListProps = {
  sections: Array<SectionType>;
  defaultSectionId?: SectionType['id'];
  size?: TileSizeType;
  children: ReactNode;
  scrollRestorationKey?: ScrollRestorationKey;
};
export function AnchorSectionList(props: AnchorSectionListProps) {
  const flashListRef = useRef<FlashList<FlashListDatum>>(null);

  const [activeSectionId, setActiveSectionId] = useState('');
  const [manualActiveSectionId, setManualActiveSectionId] = useState('');

  const flashListData = useMemo(() => {
    const data: Array<FlashListDatum> = [];

    for (const section of props.sections) {
      data.push({ type: 'section', section } as const);

      if (Array.isArray(section.items)) {
        data.push(
          ...section.items.map(
            (item, indexWithinSection) =>
              ({
                type: 'item',
                parentSectionId: section.id,
                indexWithinSection,
                item,
              } as const)
          )
        );
      } else {
        data.push({ type: 'custom', section } as const);
      }
    }

    return data;
  }, [props.sections]);

  const contextValue = useMemo(() => {
    return {
      sections: props.sections,
      defaultSectionId: props.defaultSectionId,
      size: props.size ?? 'lg',
      flashListRef,
      activeSectionId,
      setActiveSectionId,
      manualActiveSectionId,
      setManualActiveSectionId,
      flashListData,
      scrollRestorationKey: props.scrollRestorationKey,
    };
  }, [
    props.sections,
    props.defaultSectionId,
    props.size,
    props.scrollRestorationKey,
    activeSectionId,
    manualActiveSectionId,
    flashListData,
  ]);

  useEffect(() => {
    const urisToPrefetch: Array<string> = [];

    for (const datum of flashListData) {
      let url = '';

      switch (datum.type) {
        case 'item': {
          url = datum.item.image?.asset?.uri ?? '';
          break;
        }
        case 'section': {
          url = datum.section.image?.asset?.uri ?? '';
          break;
        }
        default: {
          // do nothing
        }
      }

      if (url) {
        urisToPrefetch.push(url);
      }
    }

    Image.prefetch(urisToPrefetch, 'memory-disk');
  }, [flashListData]);

  return (
    <anchorSectionListContext.Provider value={contextValue}>
      {props.children}
    </anchorSectionListContext.Provider>
  );
}
