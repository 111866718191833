import { Image } from 'expo-image';
import { Fragment } from 'react';
import { StyleSheet, View } from 'react-native';

import { tokens } from '../../tokens';
import { IconEdit } from '../icon';
import { Pressable } from '../pressable';
import { ScrollView, type ScrollViewProps } from '../scroll-view';

export type StepProps = {
  isActive?: boolean;
  image?: string;
};

export type ItemStepperProps = ScrollViewProps & {
  steps: StepProps[];
  onStepClick?: (index: number) => void;
};

export function ItemStepper(props: ItemStepperProps) {
  const { steps, onStepClick, ...scrollViewProps } = props;
  return (
    <ScrollView
      horizontal
      showsHorizontalScrollIndicator={false}
      {...scrollViewProps}
      style={[styles.scroller, scrollViewProps.style]}
      contentContainerStyle={[styles.scrollerContent, scrollViewProps.contentContainerStyle]}
    >
      {steps.map((step, index) => (
        <Fragment key={index}>
          <View style={[styles.box, step.isActive || step.image ? styles.activeBox : null]}>
            <Pressable
              style={[
                styles.itemButton,
                step.isActive ? styles.activeItemButton : null,
                !step.isActive && step.image ? styles.imageItemButton : null,
              ]}
              borderRadius={50}
              onPress={() => {
                if (onStepClick) {
                  onStepClick(index);
                }
              }}
            >
              <>
                {step.isActive ? (
                  <IconEdit size={20} color={tokens.colors.$blackOpacity30} />
                ) : step.image ? (
                  <Image source={{ uri: step.image }} contentFit="contain" style={styles.image} />
                ) : null}
              </>
            </Pressable>
          </View>
          {index < steps.length - 1 && <View style={styles.divider} />}
        </Fragment>
      ))}
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  scroller: {
    flexShrink: 1,
    flexGrow: 1,
  },
  scrollerContent: {
    padding: 4,
    alignItems: 'center',
    justifyContent: 'center',
  },
  box: { width: 40, aspectRatio: 1 },
  activeBox: {
    width: 42,
  },
  itemButton: {
    height: '100%',
    width: '100%',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: tokens.colors.$blackOpacity30,
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'visible',
    position: 'absolute',
  },
  activeItemButton: {
    borderWidth: 2,
  },
  imageItemButton: {
    borderWidth: 2,
    borderColor: tokens.colors.$success,
  },
  image: {
    aspectRatio: 1,
    height: '100%',
    overflow: 'visible',
  },
  divider: {
    height: 2,
    width: 8,
    backgroundColor: tokens.colors.$blackOpacity10,
  },
});
