import { useFocusEffect } from 'expo-router';
import { useCallback, useState } from 'react';

import { ScrollRestorationKey, scrollRestoration } from '@fhs/utils';

import { useAnchorSectionList } from './context';
import { useScrollToSection } from './use-scroll-to-section';

export const useInitialAutoScroll = (scrollRestorationKey?: ScrollRestorationKey) => {
  const [scrollDone, setScrollDone] = useState(false);
  const scrollToSection = useScrollToSection();
  const { flashListRef, defaultSectionId } = useAnchorSectionList();

  const triggerInitialAutoScroll = useCallback(() => {
    const scrollOffset = scrollRestoration.getOffset(ScrollRestorationKey.MenuList);
    if (scrollDone) {
      return;
    }
    if (!defaultSectionId && !scrollOffset) {
      setScrollDone(true);
      return;
    }
    setTimeout(() => {
      if (scrollOffset) {
        flashListRef.current?.scrollToOffset({
          offset: scrollOffset,
          animated: false,
        });
      } else if (defaultSectionId) {
        scrollToSection(defaultSectionId, { animated: false });
      }
      setScrollDone(true);
    }, 200);
  }, [defaultSectionId, flashListRef, scrollDone, scrollToSection]);

  const saveLastScrollOffset = useCallback(
    (offset: number) => {
      if (scrollDone && scrollRestorationKey) {
        scrollRestoration.storeOffset(scrollRestorationKey, offset);
      }
    },
    [scrollDone, scrollRestorationKey]
  );

  useFocusEffect(
    useCallback(() => {
      return () => {
        setScrollDone(false);
      };
    }, [])
  );

  return {
    scrollDone,
    triggerInitialAutoScroll,
    saveLastScrollOffset,
  };
};
