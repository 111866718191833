import { Image } from 'expo-image';
import { ReactNode, useEffect, useMemo, useRef } from 'react';
import { Animated, Easing, Platform, StyleSheet, View } from 'react-native';

import { createMqStyles } from '../../mq-styles';
import { tokens } from '../../tokens';
import type { ImageAssetWithAltText } from '../../types';
import { Pressable, PressableProps, usePressableState } from '../pressable';
import { Text } from '../text';

import type { BadgeType, TileSizeType } from './types';

const AnimatedImage = Animated.createAnimatedComponent(Image);

export type ProductListTileProps = PressableProps & {
  size: TileSizeType;
  image: ImageAssetWithAltText;
  isAvailable: boolean;
  badge?: BadgeType;
  title: string;
  description: ReactNode;
};

export function ProductListTile({
  size,
  image,
  isAvailable,
  badge,
  title,
  description,
  ...pressableProps
}: ProductListTileProps) {
  const stackStyles = useStackStyles();

  return (
    <Pressable
      {...pressableProps}
      disabled={!isAvailable || pressableProps.disabled}
      style={
        // Have to use StyleSheet.flatten manually due to issue with Link component's asChild functionality
        // circumventing react-native-web's automatic flattening for the style prop
        useMemo(
          () => StyleSheet.flatten([stackStyles.stack, stackStyles[size]]),
          [size, stackStyles]
        )
      }
      disabledStyle={pressableProps.disabledStyle}
      outlineStyle={[pressableStyle.pressableNoOutline, pressableProps.outlineStyle]}
      outlineHoveredStyle={[pressableStyle.pressableNoOutline, pressableProps.outlineHoveredStyle]}
      outlineDisabledStyle={[
        pressableStyle.pressableNoOutline,
        pressableProps.outlineDisabledStyle,
      ]}
      outlinePressedStyle={[pressableStyle.pressableNoOutline, pressableProps.outlinePressedStyle]}
      outlineFocusedStyle={[pressableStyle.pressableNoOutline, pressableProps.outlineFocusedStyle]}
    >
      <OutlineBox size={size} />
      <ProductListTileImage image={image} isAvailable={isAvailable} size={size} />
      <Badge type={!isAvailable ? 'OUT_OF_STOCK' : badge} />
      <TileHeading title={title} />
      <Text.Paragraph
        size="sm"
        numberOfLines={2}
        style={[descriptionStyles.description, !isAvailable && descriptionStyles.disabled]}
        // Enables ellipsis
        selectable={false}
      >
        {description}
      </Text.Paragraph>
    </Pressable>
  );
}

function ProductListTileImage(props: {
  image: ImageAssetWithAltText;
  isAvailable: boolean;
  size: TileSizeType;
}) {
  const pressableState = usePressableState();
  const isEmphasized = pressableState.hovered;
  const scaleRef = useRef(new Animated.Value(isEmphasized ? 1 : 0));

  useEffect(() => {
    Animated.timing(scaleRef.current, {
      toValue: isEmphasized ? 1 : 0,
      easing: Easing.out(Easing.ease),
      duration: 300,
      useNativeDriver: Platform.OS !== 'web',
    }).start();
  }, [isEmphasized]);

  return (
    <AnimatedImage
      recyclingKey={props.image.asset?.uri}
      source={{ uri: props.image.asset?.uri }}
      placeholder={{ blurhash: props.image.asset?.blurHash }}
      alt={props.image.altText}
      contentFit="contain"
      style={[
        imageStyles.img,
        imageStyles[props.size],
        !props.isAvailable && imageStyles.disabled,
        {
          transformOrigin: 'bottom',
          transform: [
            {
              scale: scaleRef.current.interpolate({ inputRange: [0, 1], outputRange: [1, 1.15] }),
            },
          ],
        },
      ]}
    />
  );
}

const badgeTypeToText: Record<BadgeType, string> = {
  NONE: '',
  NEW: 'New!',
  LIMITED: 'Limited!',
  OUT_OF_STOCK: 'Out of Stock',
};

function Badge(props: { type?: BadgeType }) {
  const badgeType = props.type ?? 'NONE';

  if (badgeType === 'NONE') {
    return null;
  }

  return (
    <View style={[badgeStyle.badge, badgeStyle[`badge-${badgeType}`]]}>
      <Text.Ui
        size="sm"
        weight="semibold"
        style={[badgeStyle.text, badgeStyle[`text-${badgeType}`]]}
      >
        {badgeTypeToText[badgeType]}
      </Text.Ui>
    </View>
  );
}

function OutlineBox(props: Pick<ProductListTileProps, 'size'>) {
  const pressableState = usePressableState();

  return (
    <View
      style={[
        boxStyles.box,
        boxStyles[props.size],
        pressableState.hovered && boxStyles.hovered,
        pressableState.focused && boxStyles.focused,
      ]}
    />
  );
}

function TileHeading(props: Pick<ProductListTileProps, 'title'>) {
  const pressableState = usePressableState();

  return (
    <View style={headingStyles.headingContainer}>
      <Text.Heading
        numberOfLines={2}
        type="four"
        // Enables ellipsis
        selectable={false}
        style={[
          headingStyles.heading,
          pressableState.hovered && headingStyles.hovered,
          pressableState.focused && headingStyles.focused,
          pressableState.disabled && headingStyles.disabled,
        ]}
      >
        {props.title}
      </Text.Heading>
    </View>
  );
}

const badgeStyle = StyleSheet.create({
  badge: {
    paddingHorizontal: 8,
    position: 'absolute',
    top: 0,
    right: 0,
    backgroundColor: tokens.colors.$success,
    height: 24,
    justifyContent: 'center',
    borderRadius: 4,
  },
  'badge-OUT_OF_STOCK': {
    backgroundColor: tokens.colors.$houseLight,
  },
  text: {
    color: tokens.colors.$white,
  },
  'text-OUT_OF_STOCK': {
    color: tokens.colors.$houseDark,
  },
});

const pressableStyle = StyleSheet.create({
  pressableNoOutline: {
    borderColor: 'transparent',
  },
});

const useStackStyles = createMqStyles({
  stack: {
    $base: {
      flexShrink: 1,
      flexGrow: 1,
      gap: 4,
      position: 'relative',
      backgroundColor: 'white',
      alignItems: 'center',
      padding: 16,
      paddingTop: 0,
    },

    $gteDesktop: {
      padding: 24,
      paddingTop: 0,
    },
  },
  sm: {
    $base: {},
    $gteDesktop: {
      paddingBottom: 12,
    },
  },
  lg: {
    $base: {
      paddingBottom: 20,
    },
  },
});

const boxStyles = StyleSheet.create({
  box: {
    borderRadius: 8,
    borderColor: tokens.colors.$blackOpacity10,
    borderWidth: 1,
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
  },
  lg: {
    top: 52,
  },
  sm: {
    top: 32,
  },
  hovered: {
    borderColor: tokens.colors.$houseRedOpacity30,
  },
  focused: {
    borderColor: tokens.colors.$houseRedOpacity30,
  },
});

const headingStyles = StyleSheet.create({
  headingContainer: {
    minHeight: 40,
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: '100%',
  },
  heading: {
    textAlign: 'center',
  },
  hovered: {
    color: tokens.colors.$houseRedDarken,
  },
  focused: {
    color: tokens.colors.$houseRedDarken,
  },
  disabled: {
    color: tokens.colors.$disabledText,
  },
});

const descriptionStyles = StyleSheet.create({
  description: {
    textAlign: 'center',
    minHeight: 32,
  },
  disabled: {
    color: tokens.colors.$disabledText,
  },
});

const imageStyles = StyleSheet.create({
  img: {
    width: '100%',
  },
  disabled: {
    opacity: 0.74,
  },
  lg: {
    height: 90,
  },
  sm: {
    height: 70,
  },
});
